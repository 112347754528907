import { ReactNode } from 'react';
import { cn } from '~/lib/utils';

export interface CardContainerProps {
  children: ReactNode;
}

export default function CardContainer({ children }: CardContainerProps) {
  return (
    <div
      className={cn(`relative flex flex-col rounded-lg
            w-full mx-auto
            p-4 gap-4 bg-white shadow-[0px_0px_2px_rgba(0,0,0,0.5)]
            transition-shadow duration-300 ease-in-out h-min-56 overflow-hidden`)}
    >
      {children}
    </div>
  );
}
